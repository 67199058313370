@import '~antd/dist/antd.css';
.App {
  text-align: center;
  overflow: hidden;
}

.thebros-notification {
  .ant-notification-notice-message {
    font-size: 22px;
    font-weight: 600;
  }
  .ant-notification-notice-description {
    font-size: 18px;
  }
}