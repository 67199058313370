.ant-modal-content {
    border-radius: 10px!important;
}
.ant-modal-header {
    border-bottom: 1px solid #cecece!important;
    border-radius: 10px 10px 0 0!important;
}
.ant-modal-title {
    text-align: center;
    font-weight: bold!important;
    font-size: 20px!important;
}
.ant-modal-body {
    padding-top: 0!important;
}