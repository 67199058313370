.home-hero-glow {
  top: 50%;
  left: 50%;
  width: 200%;
  transform: translate(-50%, -50%);
}

.events-none {
  pointer-events: none;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-absolute {
  position: absolute !important;
}

.signup-space,
.signup-stars {
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.signup-stars {
  background-image: radial-gradient(2px 2px at 10px 20px,
      #eee,
      rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 90px 40px, #ddd, rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-size: 200px 200px;
  opacity: 0;
  animation: zoom 15s infinite;

  &:nth-child(1) {
    background-position: 50% -40%;
    animation-delay: 0s;
  }

  &:nth-child(2) {
    background-position: 20% 60%;
    animation-delay: 2s;
  }

  &:nth-child(3) {
    background-position: 40% -80%;
    animation-delay: 4s;
  }

  &:nth-child(4) {
    background-position: -20% -30%;
    animation-delay: 6s;
  }

  &:nth-child(5) {
    background-position: 80% 30%;
    animation-delay: 8s;
  }
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0.5);
    animation-timing-function: ease-in;
  }

  85% {
    opacity: 1;
    transform: scale(1.8);
    animation-timing-function: linear;
  }

  100% {
    opacity: 0.2;
    transform: scale(2.2);
  }
}

@media (prefers-reduced-motion) {
  .signup-stars {
    animation: none;
  }
}


.home-card {
  background-color: rgba($color: #000000, $alpha: 0.1);
  width: 100%;
  max-width: 240px;
  height: 160px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  flex-direction: column;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.3);
  }

  cursor: pointer;

  img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    padding: 12px;
  }

  padding: 12px;
}

.program {
  cursor: pointer;

  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    .home-card {
      background-color: rgba($color: #000000, $alpha: 0.3);
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-card {
    padding: 4px;
  }

  .placeholder {
    padding: 4px;
  }
}